import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import SEO from 'components/SEO/SEO';
import { black, white } from 'shared/colors';
import { Font, Text } from 'components/Text/Text';
import { BREAKPOINTS } from 'shared/constants';
const accessibleLogo = require('images/accessible-logo.png');

interface Props {
  location: {
    pathname: string;
  };
}

const ADA = ({ location: { pathname } }: Props) => {
  return (
    <>
      <SEO title="Accessibility Statement | MONOGRAM" pathname={pathname} />
      <main className={css(styles.container)}>
        <img
          id="accesslogo"
          src={accessibleLogo}
          alt="Review by Accessible 360 badge"
          className={css(styles.accessibleLogo)}
        />
        <h1 className={css(styles.header)}>Accessibility Statement</h1>
        <div className={css(styles.textContainer)}>
          <Text
            text="Accessibility Assistance. If you have difficulty using or accessing
            any element of this website or on any Caliva mobile app or tool,
            please feel free to call us at (888) 688-0303 or email us at
            legal@caliva.com and we will work with you to provide the
            information, item, or transaction you seek through a communication
            method that is accessible for you consistent with applicable law
            (for example, through telephone support)."
            extraStyle={styles.text}
            font={Font.TitlingGothicNormalRegular}
          />

          <Text
            text="Goal of being Better For All.℠ Caliva desires to provide a positive
            customer experience to all our customers, and we aim to promote
            accessibility and inclusion. Our goal is to permit our customers to
            successfully gather information and transact business through our
            website."
            extraStyle={styles.text}
            font={Font.TitlingGothicNormalRegular}
          />

          <Text
            text="Whether you are using assistive technologies like a screen reader, a
            magnifier, voice recognition software, or captions for videos, our
            goal is to make your use of Caliva’s website a successful and
            enjoyable experience."
            extraStyle={styles.text}
            font={Font.TitlingGothicNormalRegular}
          />

          <Text
            text="Actions. We are taking a variety of steps and devoting resources to
            further enhance the accessibility of our website."
            extraStyle={styles.text}
            font={Font.TitlingGothicNormalRegular}
          />

          <Text
            text="Ongoing Effort. Although we are proud of the efforts that we have
            completed and that are in-progress, we at Caliva view accessibility
            as an ongoing effort."
            extraStyle={styles.text}
            font={Font.TitlingGothicNormalRegular}
          />

          <Text
            text="Feedback. Please contact us if you have any feedback or suggestions
            as to how we could improve the accessibility of this website."
            font={Font.TitlingGothicNormalRegular}
          />
        </div>
      </main>
    </>
  );
};

export default ADA;

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: black,
    color: white,
    width: '100%',
    padding: '12rem 2rem 2rem',
  },
  accessibleLogo: {
    height: 150,
    width: 150,
    margin: '0 auto',
  },
  header: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontSize: 24,
    letterSpacing: 4,
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '2.5rem 0',
  },
  textContainer: {
    width: 700,
    margin: '0 auto',
    [BREAKPOINTS.MOBILE]: {
      width: 250,
    },
  },
  text: {
    marginBottom: '1.5rem',
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '1rem',
    },
  },
});
